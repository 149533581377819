import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Switch from "./../components/switch"

const NotFoundPage = () => (
  <>
    <Seo title="Hm..." />
    <div
      id="not-found"
      className="container flex flex-col justify-center min-h-screen m-auto text-center min-w-screen"
    >
      <Switch />
      <h1
        id="fancy-text"
        className="text-xl font-extrabold tracking-wide md:text-4xl lg:text-6xl dark:text-gray-50"
      >
        Hm... Couldn't find that page.{" "}
      </h1>
      <p className="text-lg md:text-2xl lg:text-3xl dark:text-gray-100">
        Want to go <Link to="/">home</Link>?
      </p>
    </div>
  </>
)

export default NotFoundPage
